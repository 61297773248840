
import { defineComponent, onMounted, ref } from "vue";
import { useField, useForm } from "vee-validate";
import { KhoService } from "@/core/services/Kho.service";
import { Department } from "@/core/interfaces/Models";
import { DonViService } from "@/core/services/DonVi.service";
import { setCurrentPageTitle } from '@/core/helpers/breadcrumb';
import { useRouterCustom } from '@/core/hooks/use-router-custom';
import { useLoading } from '@/core/hooks/use-loading';
import { getErrorMsg, swalErrNotification, swalNotification } from '@/core/helpers/utils';
import { useButtonCustom } from '../../../../core/hooks/use-button-custom';
import { ITaiKhoanResponseBase } from '../../../../core/interfaces/ApiResponses';

import * as Yup from "yup";
import PageRowLayout from '@/layout/_shared/PageRowLayout.vue';
import ButtonCustom from '@/components/shared/buttons/ButtonCustom.vue';
import TaiKhoanSelection from '../../../../components/shared/tai-khoan-selection/TaiKhoanSelection.vue';

export default defineComponent({
  name: 'update-warehouse',

	components: {
		ButtonCustom,
		PageRowLayout,
		TaiKhoanSelection,
	},

	setup() {
		const { goBack, replace, id: warehouseId } = useRouterCustom('warehouseId');
		const { startLoading, endLoading } = useLoading();
		const { ButtonsType } = useButtonCustom();

    const departmentId = ref(0);
    const departments = ref<Department[]>([]);
    const firstInput = ref<null | HTMLFormElement>(null);

		const taiKhoanData = ref<{
			id: number;
			maTaiKhoan: string;
			maChiTiet?: string;
			tenTaiKhoan: string;
		}>({
			id: 0,
			maChiTiet: '',
			maTaiKhoan: '',
			tenTaiKhoan: '',
		});

    const updateWarehouseValidator = Yup.object({
      name: Yup.string().required("Tên kho là bắt buộc"),
      code: Yup.string().required("Mã kho là bắt buộc"),
      note: Yup.string().label("Ghi chú kho"),
      address: Yup.string().required("Địa chỉ là bắt buộc"),
    });

    const { validate } = useForm({
      validationSchema: updateWarehouseValidator,
    });

    const { value: name, errorMessage: nameErrorMess } =
      useField<string>("name");
    const { value: code, errorMessage: codeErrorMess } =
      useField<string>("code");
    const { value: note } = useField<string>("note");
    const { value: address, errorMessage: addressErrorMess } =
      useField<string>("address");

    onMounted(async () => {
			setCurrentPageTitle("Quản lý kho");
      if (firstInput.value) {
        firstInput.value.focus();
      }

      // fetched updated warehouse data
      try {
        const {
          data: { data: warehouseRes },
        } = await KhoService.fetchWarehouseDetailById(warehouseId.value.toString());
        name.value = warehouseRes.ten_kho;
        code.value = warehouseRes.ma_kho;
        address.value = warehouseRes.dia_chi;
        note.value = warehouseRes.ghi_chu;
        departmentId.value = warehouseRes.id_don_vi;

				taiKhoanData.value.maTaiKhoan = warehouseRes.tk_ketoan?.matk
				taiKhoanData.value.maChiTiet = warehouseRes.tk_ketoan?.mact

        const {
          data: { data: fetchedDepartments },
        } = await DonViService.fetchAllDepartments();
        fetchedDepartments.forEach((d) => {
          departments.value.push({
            id: d.id,
            address: d.dia_chi,
            code: d.ma_don_vi,
            createdAt: d.created_at,
            name: d.ten_don_vi,
            parentId: d.parent_id,
            systemId: d.system_id,
            updatedAt: d.updated_at,
          });
        });
      } catch (error) {
				endLoading();

				await swalNotification(
					getErrorMsg(error, 'Có lỗi khi lấy thông tin kho'),
					'error',
				);
      }
    });

    const resetButtonClickHandler = () => {
      name.value = "";
      note.value = "";
      address.value = "";
    };

		const onSubmit = async () => {
			if (!taiKhoanData.value.maChiTiet) {
				swalErrNotification(null, 'Bắt buộc phải nhập đủ thông tin tài khoản');

				return;
			}

			const { valid } = await validate();

			if (valid) {
				try {
					startLoading();
					await KhoService.updateWarehouse({
						ten: name.value,
						dia_chi: address.value,
						ma_kho: code.value,
						ghi_chu: note.value,
						id: warehouseId.value,
						id_tai_khoan: taiKhoanData.value.id,
						ma_chi_tiet: taiKhoanData.value.maChiTiet,
						ma_tai_khoan: taiKhoanData.value.maTaiKhoan,
						ten_tai_khoan: taiKhoanData.value.tenTaiKhoan,
					});

					endLoading();
					await replace(`/quan-ly/kho/chi-tiet/${warehouseId.value}`);
				} catch (error) {
					endLoading();
					await swalNotification(
						getErrorMsg(error, 'Có lỗi khi cập nhật thông tin kho'),
						'error',
					);
				}
			} else {
				return;
			}
		}

    return {
			ButtonsType,
      updateWarehouseValidator,
      departments,
      onSubmit,
      resetButtonClickHandler,
      name,
      nameErrorMess,
      code,
      codeErrorMess,
      note,
      address,
      addressErrorMess,
      departmentId,
      firstInput,
			goBack,
			taiKhoanData,
    };
  },

	methods: {
		onSelectTaiKhoan({
			taiKhoanItem: {
				id: id_tai_khoan,
				ma_tai_khoan,
				ma_chi_tiet,
				ten_tai_khoan,
			}
		}: {
			taiKhoanItem: ITaiKhoanResponseBase,
		}) {
			this.taiKhoanData.maTaiKhoan = ma_tai_khoan;
			this.taiKhoanData.tenTaiKhoan = ten_tai_khoan;
			this.taiKhoanData.id = id_tai_khoan;

			if (ma_chi_tiet) {
				this.taiKhoanData.maChiTiet = ma_chi_tiet.toString();
			}
		}
	}
});
